import "./slider.scss"
import Component from '../../shared/component'
import Splide from '@splidejs/splide';
import '@splidejs/splide/dist/css/splide.min.css';

// -----------------------------------------------------------------------------
// Constants
// -----------------------------------------------------------------------------

const CLASS = 'm-Slider'

export const ClassName = {
  ROOT: CLASS
}

export const Selector = {
  ROOT: `.${ClassName.ROOT}`
}

// -----------------------------------------------------------------------------
// Class Definition
// -----------------------------------------------------------------------------

export default class Slider extends Component {
  static upgradeAll() {
    const components = []
    Array.from(document.querySelectorAll(Selector.ROOT)).forEach(element => {
      if (element.instance == null) element.instance = new Slider(element)
      components.push(element.instance)
    })
    return components
  }

  init() {
    // console.log(this.root)

    new Splide( '.m-Slider-splide', {
      type   : 'loop',
      perPage: 1,
      classes: {
        // Add classes for arrows.
        arrows: 'splide__arrows m-Slider-arrows',
        arrow : 'splide__arrow m-Slider-arrow',
        prev  : 'splide__arrow--prev m-Slider-prev',
        next  : 'splide__arrow--next m-Slider-next',
        // Add classes for pagination.
        pagination: 'splide__pagination m-Slider-pagination', // container
        page      : 'splide__pagination__page m-Slider-page', // each button
      },
    } ).mount();
  }

}