export default class Component {

  constructor(root) {
    this.root = root
    this.init()
  }

  static upgradeAll() {
    return []
  }
  
  destroy() {
    
  }

  init() {

  }
}