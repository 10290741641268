import barba from '@barba/core'
import anime from 'animejs/lib/anime.es.js'

import '@assets/icons/icons.js'

import '@assets/images/android-chrome-192x192.png'
import '@assets/images/android-chrome-512x512.png'
import '@assets/images/apple-touch-icon.png'
import '@assets/images/browserconfig.xml'
import '@assets/images/favicon-16x16.png'
import '@assets/images/favicon-32x32.png'
import '@assets/images/favicon.ico'
import '@assets/images/mstile-70x70.png'
import '@assets/images/mstile-144x144.png'
import '@assets/images/mstile-150x150.png'
// import '@assets/images/mstile-310x150.png'
import '@assets/images/mstile-310x310.png'
import '@assets/images/safari-pinned-tab.svg'
import '@assets/images/site.webmanifest'
import '@assets/images/hero-1.jpg'

import '@shared/global.scss'

import '@components/button'
import '@components/card'
import '@components/divider'
import '@components/richText'

import Header from '@modules/header'
import Footer from '@modules/footer'
import Section from '@modules/section'
import SingleHeader from '@modules/single-header'
import SingleContent from '@modules/single-content'
import News from '@modules/news'
import Articles from '@modules/articles'
import Cards from '@modules/cards'
import Intro from '@modules/intro'
import Image from '@modules/image'
import CookieBanner from '@modules/cookie-banner'
import Hero from '@modules/hero'
import HomeArticle from '@modules/home-article'
import Slider from '@modules/slider'

class App {
  constructor(root) {
    this.instances = []
    barba.init({
      schema: {
        prefix: 'data-context',
        container: 'page',
        wrapper: 'site'
      },
      prevent: ({ el }) => {
        return el.dataset.action
      },
      transitions: [{
        name: 'default',
        sync: false,
        leave(data) {
          const animation = anime({
            targets: data.current.container,
            opacity: [1, 0],
            duration: 250,
            easing: 'linear'
          })
          return animation.finished
        },
        enter(data) {
          const animation = anime({
            targets: data.next.container,
            opacity: [0, 1],
            duration: 250,
            easing: 'linear'
          })
          return animation.finished
        }
      }]
    })
    barba.hooks.afterEnter(() => {
      document.documentElement.classList.remove('is-navigating')
    })
    barba.hooks.beforeLeave(() => {
      document.documentElement.classList.add('is-navigating')
    })
    barba.hooks.beforeEnter((data) => {
      window.scrollTo(0, 0)
      this.upgradeAll(data.next.container)
    })
    barba.hooks.afterLeave((data) => {
      this.destroy(data.current)
    })
    this.upgradeAll(root)
  }

  go(url) {
    barba.go(url)
  }

  destroy(container) {
    this.instances.forEach((instance) => {
      instance.destroy()
    })
    this.instances = []
  }

  upgradeAll(container) {
    // this.instances.push(Header.upgradeAll(container))
    this.instances.push(Slider.upgradeAll(container))
    this.instances.push(CookieBanner.upgradeAll(container))
  }
}

window.app = new App(document.querySelector(`[data-context="site"]`))